<template>
  <div>
    <Modal
      v-model="materialModal"
      width="800"
      footer-hide
    >
      <p
        slot="header"
        class="text-center"
      >
        <Icon type="ios-information-circle"></Icon>
        <span>编号：{{info.resourceCode}}</span>
        <template v-show="info.brandName!==''">
          <Icon
            type="ios-analytics"
            class="p-l-5"
          />
          (
          <span>发布品牌：<span class="text-orange">{{info.brandName}}</span> </span>
          )
        </template>
      </p>
      <div>
        <Row class="p-b-5 text-center">
          <i-col span="24">
            <div v-for="item in typeArray" :key="'tab_'+item.key"
              @click="handleChangeType(item.key)"
              :class="curType === item.key?'workplatform-radio-button-active':'workplatform-radio-button'"
              class="p-l-20 p-r-20"
            >{{ item.value }}</div>
          </i-col>
        </Row>
        <ImgLightBox ref="imgLightBox" :imgIndex.sync="imgIndex"/>
      </div>
    </Modal>
  </div>
</template>

<script>
import ImgLightBox from '@/components/common/ImgVideoLightBox'
export default {
  components: { ImgLightBox },
  data () {
    return {
      materialModal: false,
      info: {},
      typeArray: [
        { key: 1, value: '任务素材' },
        { key: 2, value: '反馈照片' },
        { key: 3, value: '监播反馈' }
      ],
      curType: 1,
      imgIndex: 0
    }
  },
  methods: {
    showModal (data = null) {
      if (data) {
        this.curType = 1
        this.info = data
        this.materialModal = true
        // 初始化显示任务素材
        const postData = {
          startIndex: 0,
          imgList: this.info.taskitemFileList
        }
        this.$refs.imgLightBox.init(postData)
      } else {
        this.$Notice.error({ desc: '非法操作，缺少必要参数' })
      }
    },
    handleChangeType (type) {
      this.curType = type
      this.imgIndex = 0
      let materialList = []
      switch (type) {
        case 1:
          // 任务素材
          materialList = this.info.taskitemFileList
          break
        case 2:
          materialList = this.info.feedBackAttachList
          break
        case 3:
          materialList = this.info.inspectAttachList
          break
      }
      this.$nextTick(() => {
        const postData = {
          startIndex: this.imgIndex,
          imgList: materialList
        }
        this.$refs.imgLightBox.init(postData)
      })
    }
  }
}
</script>
