<template>
  <div>
    <Divider
      dashed
      style="margin-bottom: 0px;"
    ><span class="divider-text">在刊查询</span></Divider>
    <p class="remark p-b-10">(PS: 仅显示当前正在发布的上刊反馈情况)</p>
    <Row class="p-b-10">
      <i-col span="24">
        <label class="title">检索日期</label>
        <DatePicker
          size="small"
          v-model="searchStartDate"
          transfer
          :options="formatMaxDate"
          @on-change="handelSearchScreen"
          type="date"
          format="yyyy-MM-dd"
          :clearable="false"
          placeholder="选择日期"
          style="width: 200px"
        ></DatePicker>
      </i-col>
    </Row>
    <Row class="p-b-10">
      <i-col span="24">
        <label class="title">媒体类型</label>
        <div
          v-for="item in mediaTypeArray"
          :key="'mtype_'+item.key"
          @click="handelChangeMediaType(item.key)"
          :class="chooseMediaType===item.key?'workplatform-radio-button-active':'workplatform-radio-button'"
        >{{ item.value }}</div>
        <!-- <div @click="handelChangeMediaType(1)" :class="chooseMediaType===1?'workplatform-radio-button-active':'workplatform-radio-button'">电子屏</div> -->
      </i-col>
    </Row>
    <Row class="p-b-10">
      <i-col span="24">
        <label class="title">发布来源</label>
        <div
          v-if="chooseMediaType===0"
          @click="handelChangePublishType(0)"
          :class="choosePublishType===0?'workplatform-radio-button-active':'workplatform-radio-button'"
        >全部</div>
        <div
          @click="handelChangePublishType(1)"
          :class="choosePublishType===1?'workplatform-radio-button-active':'workplatform-radio-button'"
        >客户</div>
        <div
          v-if="chooseMediaType===0"
          @click="handelChangePublishType(11)"
          :class="choosePublishType===11?'workplatform-radio-button-active':'workplatform-radio-button'"
        >填充</div>
      </i-col>
    </Row>
    <template v-if="choosePublishType!==0">
      <Row class="p-b-10">
        <i-col span="24">
          <label class="title">发布类型</label>
          <div
            @click="handleChangeOrderType(0)"
            class="m-b-2"
            :class="chooseOrderType===0?'workplatform-radio-button-active':'workplatform-radio-button'"
          >全部</div>
          <div
            v-for="(item,index) in orderType"
            :key="'o'+index"
            @click="handleChangeOrderType(item.type)"
            class="m-b-2"
            :class="item.type===chooseOrderType?'workplatform-radio-button-active':'workplatform-radio-button'"
          >
            {{item.typeName}}
          </div>
        </i-col>
      </Row>

      <Row class="p-b-10">
        <i-col span="24">
          <label class="title">关键字</label>
          <i-input
            size="small"
            style="width:200px;"
            class="m-l-5"
            v-model="screenQuery.keyword"
            :placeholder="choosePublishType===1?'品牌、客户关键字搜索...':'画面名称关键字搜索...'"
          />
        </i-col>
      </Row>
      <Row class="p-b-10">
        <i-col span="24">
          <label class="title">操作</label>
          <i-button
            size="small"
            type="primary"
            icon="ios-search"
            class="m-l-20"
            @click="handelSearchScreen"
          >搜索</i-button>
        </i-col>
      </Row>

      <div
        v-for="(item,index) in ScreenList"
        :key="'s'+index"
        @click="handleClickScreen(item)"
      >
        <p :class="item===chooseScreen?'demand-table-row-active':'demand-table-row'">
          <span
            class="p-l-5"
            v-if="choosePublishType===1"
          >{{item.brandName === ''? item.advertiserName:item.brandName}} ({{item.count}})</span>
          <span
            class="p-l-5"
            v-else
          >{{item.fileName}} ({{item.count}})</span>
        </p>
      </div>

      <p
        class="text-center p-t-10 text-16 text-gray"
        v-if="ScreenList.length===0"
      >暂无数据</p>
      <div
        class="paging_style"
        v-if="ScreenList.length!==0"
      >
        <Page
          size="small"
          :total="totalScreen"
          :page-size="screenQuery.pageSize"
          show-total
          show-elevator
          :current="screenQuery.pageNumber"
          @on-change="handlePageChange"
        ></Page>
      </div>
    </template>
    <List ref="list" />
  </div>
</template>

<script>
import { GetDateStr, ParseDate } from '@/utils/dateFormat'
import { getAdTypes, getOrderPublishAdPage, getFillPublishPage, getTrainPublishAdPage } from '@/api/msp/publishstatistical'
import { getElecPublishAdPage } from '@/api/msp/elecpublishstats'
import List from './List.vue'

export default {
  components: { List },
  data () {
    return ({
      mediaTypeArray: [
        { key: 0, value: '常规灯箱' },
        { key: 1, value: '电子屏' },
        { key: 2, value: '列车' }
      ],
      searchStartDate: GetDateStr(Date.now(), 0),
      formatMaxDate: {
        disabledDate (date) {
          return date.valueOf() > Date.now() + 86400000
        }
      },
      chooseMediaType: 0,
      choosePublishType: 0,

      orderType: [],
      chooseOrderType: 0, // 选中的订单类型ID

      screenQuery: {
        pageNumber: 1,
        pageSize: 15,
        publishDate: '',
        adTypes: null,
        keyword: ''
      },

      ScreenList: [],
      totalScreen: 0,
      chooseScreen: null
    })
  },
  mounted () {
    this.$store.commit('set_statistic_publishDate', ParseDate(this.searchStartDate))
    setTimeout(() => {
      this.loadOrderType()
    },
    500)
  },
  methods: {
    handelChangePublishType (type) {
      this.choosePublishType = type
      this.loadOrderType()
    },
    handelChangeMediaType (type) {
      this.chooseMediaType = type
      this.$store.commit('set_statistic_mediaType', type)
      this.handelChangePublishType([1, 2].includes(type) ? 1 : 0) // 电子屏和列车默认选中客户项
      // this.handelChangePublishType(type === 1 ? 1 : 0)
    },
    loadOrderType () {
      this.ScreenList = []

      if (this.choosePublishType === 0) { // 全部
        this.orderType = []
        // 直接加载地图查询
        this.$store.commit('set_statistic_adType', null)

        this.handleClickScreen()
      } else {
        const postData = {
          publishDate: ParseDate(this.searchStartDate),
          publishSourceType: this.choosePublishType
        }

        getAdTypes(postData).then(res => {
          this.orderType = res
          // 默认加载全部
          this.handleChangeOrderType(0)
        })
      }
    },
    handleChangeOrderType (type) {
      this.chooseOrderType = type
      this.screenQuery.adTypes = (this.chooseOrderType === 0) ? JSON.stringify(this.orderType.map(x => x.type)) : JSON.stringify([type])
      this.ScreenList = []
      this.totalScreen = 0

      this.handelSearchScreen()
    },
    handelSearchScreen () {
      this.screenQuery.pageNumber = 1
      this.loadBrandData()
    },
    loadBrandData () {
      this.screenQuery.publishDate = ParseDate(this.searchStartDate)

      if (this.chooseMediaType === 1) {
        // 电子屏-客户
        getElecPublishAdPage(this.screenQuery).then(res => {
          this.ScreenList = res.list
          this.totalScreen = res.totalRow
        })
        this.handleClickScreen(this.chooseScreen)
      } else if (this.chooseMediaType === 2) {
        // 列车
        if (this.choosePublishType === 1) {
          // 客户
          getTrainPublishAdPage(this.screenQuery).then(res => {
            if (res && !res.errcode) {
              this.ScreenList = res.list
              this.totalScreen = res.totalRow
            }
          })
        }
      } else {
        // 常规灯箱
        if (this.choosePublishType === 1) {
          // 客户
          getOrderPublishAdPage(this.screenQuery).then(res => {
            this.ScreenList = res.list
            this.totalScreen = res.totalRow
          })
        } else {
          // 填充
          getFillPublishPage(this.screenQuery).then(res => {
            this.ScreenList = res.list
            this.totalScreen = res.totalRow
          })
        }
        this.handleClickScreen(this.chooseScreen)
      }

      // // 电子屏
      // if (this.chooseMediaType === 1) {
      //   getElecPublishAdPage(this.screenQuery).then(res => {
      //     this.ScreenList = res.list
      //     this.totalScreen = res.totalRow
      //   })
      // } else if (this.choosePublishType === 1) {
      //   getOrderPublishAdPage(this.screenQuery).then(res => {
      //     this.ScreenList = res.list
      //     this.totalScreen = res.totalRow
      //   })
      // } else {
      //   getFillPublishPage(this.screenQuery).then(res => {
      //     this.ScreenList = res.list
      //     this.totalScreen = res.totalRow
      //   })
      // }

      this.$store.commit('set_statistic_publishDate', this.screenQuery.publishDate)
      this.$store.commit('set_statistic_adType', this.screenQuery.adTypes)
      this.$store.commit('set_statistic_keyword', this.screenQuery.keyword)
      // this.handleClickScreen(this.chooseScreen)
    },
    handlePageChange (page) {
      this.screenQuery.pageNumber = page
      this.loadBrandData()
    },
    handleClickScreen (screen) {
      if (this.chooseScreen === screen || !screen) {
        if (this.chooseMediaType !== 2) { // 非列车类型
          this.chooseScreen = null
          this.$store.commit('set_statistic_advertiserId', null)
          this.$store.commit('set_statistic_brandId', null)
          this.$store.commit('set_statistic_fileId', null)
        }
      } else {
        this.chooseScreen = screen
        if (this.choosePublishType === 1) { // 客户
          this.$store.commit('set_statistic_advertiserId', screen.advertiserId)
          this.$store.commit('set_statistic_brandId', screen.brandId === -1 ? null : screen.brandId)
        } else {
          this.$store.commit('set_statistic_fileId', screen.fileKey)
        }
      }
      if (this.chooseMediaType === 2) {
        // 列车在刊信息查看
        this.$nextTick(() => {
          this.$refs.list.showModal()
        })
      } else {
        // 常规灯箱和电子屏
        this.$store.commit('set_statistic_update', new Date())
      }
    },
    ExportDemandFile () {
      this.$refs.exportDemandFile.init()
    }
  }
}
</script>
