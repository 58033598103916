<template>
  <div>
    <Modal
      v-model="modalShow"
      width="80%"
      footer-hide
    >
      <p
        slot="header"
        class="text-center"
      >列车在刊信息查看</p>
      <div>
        <Table
          stripe
          size="small"
          :data="list"
          :columns="tableColumns"
          :loading="tableLoading"
        >
        </Table>
        <!-- <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-elevator
            :current="query.pageNumber"
            @on-change="handleChangePage"
          ></Page>
        </div> -->
      </div>
    </Modal>
    <MaterialView ref="materialView"/>
  </div>
</template>

<script>
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { getTrainPublishDetail } from '@/api/msp/publishstatistical'
import MaterialView from './MaterialView.vue'
export default {
  components: { MaterialView },
  data () {
    return {
      modalShow: false,
      query: {
        pageNumber: 1,
        pageSize: 15,
        keyword: ''
      },
      tableLoading: false,
      list: [],
      // total: 0,
      tableColumns: [
        { title: '编号', align: 'center', key: 'resourceCode' },
        { title: '类型', align: 'center', key: 'stationName' },
        {
          title: '客户（品牌）',
          align: 'center',
          key: 'advertiserName',
          render: (h, { row }) => {
            return h('span', `${row.advertiserName}（${row.brandName}）`)
          }
        },
        {
          title: '档期',
          align: 'center',
          render: (h, { row }) => {
            return h('span', row.startDate !== '' ? GetCurrentSchedule(row.startDate, row.endDate, false) : '/')
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, { row }) => {
            const html = []
            html.push(h('a', {
              on: {
                click: () => {
                  this.onView(row)
                }
              }
            }, '查看'))
            return h('div', html)
          }
        }
      ]
    }
  },
  computed: {
    publishDate () {
      return this.$store.state.statistic.publishDate
    },
    adType () {
      return this.$store.state.statistic.adType
    },
    advertiserId () {
      return this.$store.state.statistic.advertiserId
    },
    mediaType () {
      return this.$store.state.statistic.mediaType
    }
  },
  methods: {
    showModal () {
      this.getTableData()
      this.modalShow = true
    },
    getTableData () {
      this.tableLoading = true
      this.list = []
      const postData = {
        publishDate: this.publishDate,
        adTypes: this.adType,
        advertiserId: this.advertiserId,
        keyword: '',
        publishSourceType: undefined
      }
      getTrainPublishDetail(postData).then(res => {
        if (res && !res.errcode) {
          this.list = res
        }
      }).finally(() => { this.tableLoading = false })
    },
    // handleChangePage (page) {
    //   this.query.pageNumber = page
    //   this.getTableData()
    // },
    onView (params) {
      this.$nextTick(() => {
        this.$refs.materialView.showModal(params)
      })
    }
  }
}
</script>
